import React, { useRef, useLayoutEffect } from "react";
import { Helmet } from "react-helmet"
import { Link } from "gatsby"

import Layout from "../components/layout"
import { StaticImage } from "gatsby-plugin-image"

import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import animateReveal from "../components/gs_reveal";

export default function Home() {

  gsap.registerPlugin(ScrollTrigger);

  const revealRefs = useRef([]);
  revealRefs.current = [];

  useLayoutEffect(() => {
    gsap.registerPlugin(ScrollTrigger)
    let scrollTriggers = [];
    scrollTriggers = animateReveal(revealRefs.current);
    return () => {
      scrollTriggers.forEach((t) => t.kill(true));
    };
  }, []);

  const addToRevealRefs = (el) => {
    if (el && !revealRefs.current.includes(el)) {
      revealRefs.current.push(el);
    }
  };
  return (
    <Layout>
      <Helmet
        title="Empresa de reformas | Pide presupuesto ya"
        meta={[
          { name: 'description', content: 'Reformas.Top es una empresa de reformas integrales. Pide presupuesto gratis y sin compromiso.' },
        ]}
      />
      <div className="relative">
        <div className="absolute inset-x-0 bottom-0 h-1/2 bg-gray-100"></div>
        <div className="relative shadow-xl sm:overflow-hidden">
          <div className="absolute inset-0">
            <StaticImage className="h-full w-full object-cover" src="../images/reformas-top.webp" alt="Reformas integrales" />
          </div>
          <div className="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8">
            <h1 ref={addToRevealRefs} className="text-center text-5xl tracking-tight sm:text-6xl lg:text-7xl text-white max-w-5xl mx-auto">
              <strong>Transforma</strong> tu hogar con <strong>Reformas integrales</strong>
            </h1>
            <p className="mt-6 max-w-lg mx-auto text-center text-2xl text-white sm:max-w-3xl"> Realiza reformas, pinta tu
              hogar, construye un espacio nuevo y mucho más a tan solo un click.</p>
            <div className="mt-10 max-w-sm mx-auto sm:max-w-none sm:flex sm:justify-center">
              <div className="space-y-4 sm:space-y-0 sm:mx-auto sm:inline-grid sm:grid-cols-2 sm:gap-5">
                {/* <!-- buscador--> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="mt-20">
        <div className="container">
          <div className="max-w-4xl mx-auto text-center">
            <h2>Encuentra profesionales en reformas en España</h2>
          </div>
          <div
            className="my-20 grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 2xl:grid-cols-6 gap-10 items-center justify-items-center">
            <div>

              <Link
                to={`/reformas-ciudad/`}
                state={{ ciudad: 'Madrid' }}
              >
                <figure className="relative max-w-xs">
                  <StaticImage src="../images/madrid.webp" alt="Reformas en Madrid" />
                  <figcaption className="absolute bottom-5 left-0 right-0 text-white font-semibold text-xl text-center">Madrid
                  </figcaption>
                </figure>
              </Link>
            </div>
            <div>
            <Link
                to={`/reformas-ciudad/`}
                state={{ ciudad: 'Catalunya' }}
              >
              <figure className="relative max-w-xs">
                <StaticImage src="../images/reformas-catalunya.webp" alt="Reformas en Catalunya" />
                <figcaption className="absolute bottom-5 left-0 right-0 mx-auto text-white font-semibold text-xl text-center">
                  Catalunya
                </figcaption>
              </figure> </Link>
            </div>
            <div>
              <figure className="relative max-w-xs">
                <StaticImage src="../images/reformas-valenciana.webp" alt="Reformas en Comunidad Valenciana" />
                <figcaption className="absolute bottom-5 left-0 right-0 mx-auto text-white font-semibold text-xl text-center">
                  Comunidad Valenciana
                </figcaption>
              </figure>
            </div>
            <div>
              <figure className="relative max-w-xs">
                <StaticImage src="../images/reformas-murcia.webp" alt="Reformas en Murcia" />
                <figcaption className="absolute bottom-5 left-0 right-0 mx-auto text-white font-semibold text-xl text-center">
                  Murcia
                </figcaption>
              </figure>
            </div>
            <div>
              <figure className="relative max-w-xs">
                <StaticImage src="../images/reformas-andalucia.webp" alt="Reformas en Andalucía" />
                <figcaption className="absolute bottom-5 left-0 right-0 mx-auto text-white font-semibold text-xl text-center">
                  Andalucía
                </figcaption>
              </figure>

            </div>
            <div>
              <figure className="relative max-w-xs">
                <StaticImage src="../images/reformas-extremadura.webp" alt="Reformas en Extremadura" />
                <figcaption className="absolute bottom-5 left-0 right-0 mx-auto text-white font-semibold text-xl text-center">
                  Extremadura
                </figcaption>
              </figure>

            </div>
            <div>
              <figure className="relative max-w-xs">
                <StaticImage src="../images/reformas-castilla-leon.webp" alt="Reformas en Castilla y León" />
                <figcaption className="absolute bottom-5 left-0 right-0 mx-auto text-white font-semibold text-xl text-center">
                  Castilla y León
                </figcaption>
              </figure>

            </div>
            <div>
              <figure className="relative max-w-xs">
                <StaticImage src="../images/reformas-galicia.webp" alt="Reformas en Galicia" />
                <figcaption className="absolute bottom-5 left-0 right-0 mx-auto text-white font-semibold text-xl text-center">
                  Galicia
                </figcaption>
              </figure>

            </div>
            <div>
              <figure className="relative max-w-xs">
                <StaticImage src="../images/reformas-asturias.webp" alt="Reformas en Asturias" />
                <figcaption className="absolute bottom-5 left-0 right-0 mx-auto text-white font-semibold text-xl text-center">
                  Asturias
                </figcaption>
              </figure>

            </div>
            <div>
              <figure className="relative max-w-xs">
                <StaticImage src="../images/reformas-cantabria.webp" alt="Reformas en Cantabria" />
                <figcaption className="absolute bottom-5 left-0 right-0 mx-auto text-white font-semibold text-xl text-center">
                  Cantabria
                </figcaption>
              </figure>

            </div>
            <div>
              <figure className="relative max-w-xs">
                <StaticImage src="../images/reformas-navarra.webp" alt="Reformas en Navarra" />
                <figcaption className="absolute bottom-5 left-0 right-0 mx-auto text-white font-semibold text-xl text-center">
                  Navarra
                </figcaption>
              </figure>

            </div>
            <div>
              <figure className="relative max-w-xs">
                <StaticImage src="../images/reformas-islas-canarias.webp" alt="Reformas en Islas canarias" />
                <figcaption className="absolute bottom-5 left-0 right-0 mx-auto text-white font-semibold text-xl text-center">
                  Islas canarias
                </figcaption>
              </figure>

            </div>
            <div>
              <figure className="relative max-w-xs">
                <StaticImage src="../images/reformas-Illes-balears.webp" alt="Reformas en Islas canarias" />
                <figcaption className="absolute bottom-5 left-0 right-0 mx-auto text-white font-semibold text-xl text-center">
                  Illes balears
                </figcaption>
              </figure>

            </div>
            <div>
              <figure className="relative max-w-xs">
                <StaticImage src="../images/reformas-aragon.webp" alt="Reformas en Aragón" />
                <figcaption className="absolute bottom-5 left-0 right-0 mx-auto text-white font-semibold text-xl text-center">
                  Aragón
                </figcaption>
              </figure>

            </div>
            <div>
              <figure className="relative max-w-xs">
                <StaticImage src="../images/reformas-la-rioja.webp" alt="Reformas en La rioja" />
                <figcaption className="absolute bottom-5 left-0 right-0 mx-auto text-white font-semibold text-xl text-center">
                  La rioja
                </figcaption>
              </figure>
            </div>
            <div>
              <figure className="relative max-w-xs">
                <StaticImage src="../images/reformas-pais-vasco.webp" alt="Reformas en País vasco" />
                <figcaption className="absolute bottom-5 left-0 right-0 mx-auto text-white font-semibold text-xl text-center">
                  País vasco
                </figcaption>
              </figure>
            </div>
          </div>
        </div>
        <div>
          <div className="container my-20">
            <div className="max-w-5xl mx-auto text-center mb-20 ">
              <h2>Beneficios de contratar servicios profesionales en Reformas</h2>
            </div>
            <div className=" grid grid-cols-1 xl:grid-cols-4  gap-5 pb-10">
              <div ref={addToRevealRefs} className="flex flex-nowrap items-center rounded-3xl px-5 py-10 bg-white shadow-2xl"><span
                className="mr-3 text-primary font-semibold text-6xl block">1.</span>
                <p className="text-sm font-medium">Ofrecemos garantía en caso de fraude</p>
              </div>
              <div ref={addToRevealRefs} className="flex flex-nowrap items-center rounded-3xl px-5 py-10 bg-white shadow-2xl"><span
                className="mr-3 text-primary font-semibold text-6xl block">2.</span>
                <p className="text-sm font-medium">Conseguirás un amplio directorio de profesionales en el área que buscas
                </p>
              </div>
              <div ref={addToRevealRefs} className="flex flex-nowrap items-center rounded-3xl px-5 py-10 bg-white shadow-2xl"><span
                className="mr-3 text-primary font-semibold text-6xl block">3.</span>
                <p className="text-sm font-medium">Amplitud de servicios</p>
              </div>
              <div ref={addToRevealRefs} className="flex flex-nowrap items-center rounded-3xl px-5 py-10 bg-white shadow-2xl"><span
                className="mr-3 text-primary font-semibold text-6xl block">4.</span>
                <p className="text-sm font-medium">Contamos con equipo de expertos con características proactivas y
                  creativas</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="mt-20">
        <div className="container">
          <div className="max-w-6xl mx-auto text-center mb-20 ">
            <h2>Servicios en Contrucciones y reformas <span className="text-5xl font-normal block">que ofrecen nuestros
              profesionales</span></h2>
          </div>
          <div className=" grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-5">
            <div className="bg-white rounded-3xl shadow-2xl p-5">
              <div className=" flex flex-nowrap items-center">
                <div className="bg-primary w-16 h-16 rounded-3xl flex justify-center items-center mr-3"><span  ref={addToRevealRefs}
                  className="ico-service block icon-repairing-service"></span></div>
                <p>Instalaciones</p>
              </div>
            </div>
            <div className="bg-white rounded-3xl shadow-2xl p-5">
              <div className=" flex flex-nowrap items-center">
                <div className="bg-primary w-16 h-16 rounded-3xl flex justify-center items-center mr-3"><span  ref={addToRevealRefs}
                  className="ico-service block icon-mop"></span></div>
                <p>Limpieza</p>
              </div>
            </div>
            <div className="bg-white rounded-3xl shadow-2xl p-5">
              <div className=" flex flex-nowrap items-center">
                <div className="bg-primary w-16 h-16 rounded-3xl flex justify-center items-center mr-3"><span  ref={addToRevealRefs}
                  className="ico-service block icon-home"></span></div>
                <p>Reformas de viviendas</p>
              </div>
            </div>
            <div className="bg-white rounded-3xl shadow-2xl p-5">
              <div className=" flex flex-nowrap items-center">
                <div className="bg-primary w-16 h-16 rounded-3xl flex justify-center items-center mr-3"><span  ref={addToRevealRefs}
                  className="ico-service block icon-bath"></span></div>
                <p>Reformas de baños</p>
              </div>
            </div>
            <div className="bg-white rounded-3xl shadow-2xl p-5">
              <div className=" flex flex-nowrap items-center">
                <div className="bg-primary w-16 h-16 rounded-3xl flex justify-center items-center mr-3"><span  ref={addToRevealRefs}
                  className="ico-service block icon-bath icon-briefcase"></span></div>
                <p>Reformas de oficinas</p>
              </div>
            </div>
            <div className="bg-white rounded-3xl shadow-2xl p-5">
              <div className=" flex flex-nowrap items-center">
                <div className="bg-primary w-16 h-16 rounded-3xl flex justify-center items-center mr-3"><span  ref={addToRevealRefs}
                  className="ico-service block icon-store"></span></div>
                <p>Reformas de comercios</p>
              </div>
            </div>

            <div className="bg-white rounded-3xl shadow-2xl p-5">
              <div className=" flex flex-nowrap items-center">
                <div className="bg-primary w-16 h-16 rounded-3xl flex justify-center items-center mr-3"><span  ref={addToRevealRefs}
                  className="ico-service block icon-ruler"></span></div>
                <p>Arquitectura</p>
              </div>
            </div>
            <div className="bg-white rounded-3xl shadow-2xl p-5">
              <div className=" flex flex-nowrap items-center">
                <div className="bg-primary w-16 h-16 rounded-3xl flex justify-center items-center mr-3"><span  ref={addToRevealRefs}
                  className="ico-service block  icon-living-room"></span></div>
                <p>Diseño de interiores</p>
              </div>
            </div>

            <div className="bg-white rounded-3xl shadow-2xl p-5">
              <div className=" flex flex-nowrap items-center">
                <div className="bg-primary w-16 h-16 rounded-3xl flex justify-center items-center mr-3"><span ref={addToRevealRefs}
                  className="ico-service block icon-waterproof"></span></div>  
                <p>Impermeabilizaciones</p>
              </div>
            </div>

            <div className="bg-white rounded-3xl shadow-2xl p-5">
              <div className=" flex flex-nowrap items-center">
                <div className="bg-primary w-16 h-16 rounded-3xl flex justify-center items-center mr-3"><span  ref={addToRevealRefs}
                  className="ico-service block icon-shovel"></span></div>
                <p>Albañileria</p>
              </div>
            </div>

            <div className="bg-white rounded-3xl shadow-2xl p-5">
              <div className=" flex flex-nowrap items-center">
                <div className="bg-primary w-16 h-16 rounded-3xl flex justify-center items-center mr-3"><span  ref={addToRevealRefs}
                  className="ico-service block  icon-kitchen"></span></div>
                <p>Reformas de Cocinas</p>
              </div>
            </div>

            <div className="bg-white rounded-3xl shadow-2xl p-5">
              <div className=" flex flex-nowrap items-center">
                <div className="bg-primary w-16 h-16 rounded-3xl flex justify-center items-center mr-3"><span  ref={addToRevealRefs}
                  className="ico-service block  icon-plastering"></span></div>
                <p>Pladur</p>
              </div>
            </div>

            <div className="bg-white rounded-3xl shadow-2xl p-5">
              <div className=" flex flex-nowrap items-center">
                <div className="bg-primary w-16 h-16 rounded-3xl flex justify-center items-center mr-3"><span  ref={addToRevealRefs}
                  className="ico-service block icon-pipeline"></span></div>
                <p>Fontaneria</p>
              </div>
            </div>

            <div className="bg-white rounded-3xl shadow-2xl p-5">
              <div className=" flex flex-nowrap items-center">
                <div className="bg-primary w-16 h-16 rounded-3xl flex justify-center items-center mr-3"><span  ref={addToRevealRefs}
                  className="ico-service block icon-flash"></span></div>
                <p>Servicios Eléctricos</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="relative my-20" >
        <div className="absolute inset-x-0 bottom-0 h-1/2 bg-gray-100"></div>
        <div className="relative shadow-xl sm:overflow-hidden">
          <div className="absolute inset-0">
            <StaticImage className="h-full w-full object-cover" src="../images/trabajo-en-el-sector-de-reformas.webp" alt="Reformas integrales" />
          </div>
          <div className="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8">
            <div className="max-w-4xl mx-auto text-center">
              <h2 className=" text-white text-5xl lg:text-6xl mb-5">¿Buscas trabajo y te dedicas al sector de reformas?</h2>
              <p className=" text-2xl text-white mb-16">Regístrate y contacta al instante a personas que buscan profesionales en
                el área de reformas, mudanzas, instalaciones, arquitectura, decoración y mucho más.</p>
              <button className=" rounded-lg py-6 px-24 bg-white text-2xl text-gray">Registrarse</button>
            </div>

          </div>
        </div>
      </div>
      <div>
        <div className="container">
          <div className="max-w-3xl mx-auto text-center">
            <h2 className="mb-5">Esto opinan nuestros usuarios </h2>
            <p>Esto es lo que opinan personas como tu de los profesionales suscritos a nuestra plataforma</p>
          </div>
          <div className=" grid lg:grid-cols-3 gap-5 my-10">
            <div className=" my-10 p-10  bg-white shadow-xl rounded-2xl">
              <figure className="flex justify-around items-center">
                <StaticImage className="w-20 h-20 rounded-full" src="../images/testimonial.jpg" alt="Testimonio Reformas integrales" />
                <div className=" text-gray">
                  <blockquote>
                    <p className="leading-4	text-2xl font-medium">
                      Lorem Ipsum
                    </p>
                  </blockquote>
                  <figcaption>
                    <div>
                      <span className=" text-sm">Andalucía - Almería</span>
                    </div>
                  </figcaption>
                </div>
              </figure>
              <p className="text-gray leading-5 mt-10 text-xs">Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                Aenean commodo ligula eget
                dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus
                mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis
                enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu. In enim justo, rhoncus ut,
                imperdiet a, venenatis vitae, justo. Nullam dictum felis eu pede mollis pretium. Integer tincidunt.</p>
              <div className=" flex">
                <StaticImage src="../images/start.svg" alt="review" className="mx-1" />
                <StaticImage src="../images/start.svg" alt="review" className="mx-1" />
                <StaticImage src="../images/start.svg" alt="review" className="mx-1" />
                <StaticImage src="../images/start.svg" alt="review" className="mx-1" />
                <StaticImage src="../images/start.svg" alt="review" className="mx-1" />
              </div>
            </div>
            <div className=" p-10  bg-white shadow-xl rounded-2xl">
              <figure className="flex justify-between items-center">
                <StaticImage className="w-20 h-20 rounded-full" src="../images/testimonial.jpg" alt="Testimonio Reformas integrales" />
                <div>
                  <blockquote>
                    <p>
                      Lorem Ipsum
                    </p>
                  </blockquote>
                  <figcaption className="font-medium">
                    <div>
                      Andalucía - Almería
                    </div>
                  </figcaption>
                </div>
              </figure>
              <p className=" mt-10 mb-5 text-sm">Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo
                ligula eget
                dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus
                mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis
                enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu. In enim justo, rhoncus ut,
                imperdiet a, venenatis vitae, justo. Nullam dictum felis eu pede mollis pretium. Integer tincidunt.</p>
              <div className=" flex">{/* <img src="~/assets/images/start.svg" alt="review" className="mx-1"
                width="27.7px" height="26.6px"> */}

              </div>
            </div>
            <div className=" my-10 p-10 bg-white shadow-xl rounded-2xl">
              <figure className="flex justify-around items-center">
                <StaticImage className="w-20 h-20 rounded-full" src="../images/testimonial.jpg" alt="Testimonio Reformas integrales" />
                <div className=" text-gray">
                  <blockquote>
                    <p className="leading-4	text-2xl font-medium">
                      Lorem Ipsum
                    </p>
                  </blockquote>
                  <figcaption>
                    <div>
                      <span className=" text-sm">Andalucía - Almería</span>
                    </div>
                  </figcaption>
                </div>
              </figure>
              <p className="text-gray leading-5 mt-10 text-xs">Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                Aenean commodo ligula eget
                dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus
                mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis
                enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu. In enim justo, rhoncus ut,
                imperdiet a, venenatis vitae, justo. Nullam dictum felis eu pede mollis pretium. Integer tincidunt.</p>
              <div className=" flex">{/* <img v-for="n in 4" :key="n" src="~/assets/images/start.svg" alt="review" className="mx-1"
                width="27.7px" height="26.6px"> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}